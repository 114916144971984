.listGroupItem {
    min-width: 140px;
    text-align: center;
    text-decoration: none;
    color: #000;
    padding: 10px;
}

.listGroupItem {
    min-width: 140px;
    text-align: center;
    text-decoration: none;
    color: #000;
    padding: 10px;
}

.listGroupItem:hover {
    color: black;
}

.active {
    background-color: #6dae45;
    color: white;
}

.categoryTitle {
    padding-top: 30px;
    font-size: 20pt;
    /* font-weight: 200; */
    /* color: #6dae45; */
    text-transform: uppercase;
}